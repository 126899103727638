export const MAIN_PAGE_IMAGE =
  'https://images.unsplash.com/photo-1491895200222-0fc4a4c35e18?q=80&w=2148&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

// Good MAIN_PAGE_IMAGE image choices
// https://images.unsplash.com/photo-1558591710-4b4a1ae0f04d?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
// https://images.unsplash.com/photo-1491895200222-0fc4a4c35e18?q=80&w=2148&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
// https://images.unsplash.com/photo-1550537687-c91072c4792d?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
// https://images.unsplash.com/photo-1604147706283-d7119b5b822c?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D

export const LOGO_IMAGE = '/images/logo/mihaianton.webp';
export const LOGO_DEFAULT_SIZE = {
  width: 80,
  height: 80,
};
export const LOGO_ALT = 'Mihai Anton';

export const NOT_FOUND_IMAGE = '/images/404/404.svg';
export const GOOGLE_AUTH_IMAGE = '/images/auth/google_auth.png';

// blog
export const BLOG_AUTOML_DATA = '/images/content/blog/dataHasABetterIdea.jpg';
export const BLOG_AUTOML_MODEL_COMPARISON = '/images/content/blog/ModelComparison.png';
export const BLOG_TUDELFT = '/images/content/blog/tu_delft.jpg';
export const BLOG_TUDELFT_LIBRARY = '/images/content/blog/tud_library.jpeg';
export const BLOG_TOP10APPS_SPOTIFY = '/images/content/blog/top10apps/spotify.png';
export const BLOG_TOP10APPS_SLEEPCYCLE = '/images/content/blog/top10apps/sleepCycle.png';
export const BLOG_TOP10APPS_NOTION = '/images/content/blog/top10apps/notion.png';
export const BLOG_TOP10APPS_HEADSPACE = '/images/content/blog/top10apps/headspace.png';
export const BLOG_TOP10APPS_TOGGL = '/images/content/blog/top10apps/toggl.png';
export const BLOG_TOP10APPS_KINDLE = '/images/content/blog/top10apps/kindle.png';
export const BLOG_TOP10APPS_AUDIBLE = '/images/content/blog/top10apps/audible.png';
export const BLOG_TOP10APPS_GOODNOTES = '/images/content/blog/top10apps/goodNotes5.png';
export const BLOG_TOP10APPS_TODOIST = '/images/content/blog/top10apps/todoist.png';
export const BLOG_TOP10APPS_APPLECALENDAR = '/images/content/blog/top10apps/appleCalendar.png';
export const BLOG_TOP10APPS = '/images/content/blog/top10apps/top10apps.png';
export const BLOG_COMPOUNDING_HABITS_101 = '/images/content/blog/101-365.jpg';
export const BLOG_COMPOUNDING_HABITS_MARGINAL_GAINS = '/images/content/blog/marginal-gains-700.jpg';
export const BLOG_IIC_PREVIEW = '/images/content/blog/iic-preview.png';
export const BLOG_WORD2_VEC = '/images/content/blog/word2vec/word2veccontextcenter.png';
export const BLOG_WORD2_VEC_ARCHITECTURE = '/images/content/blog/word2vec/word2vecarchitecture.png';
export const BLOG_WORD2_VEC_EXAMPLE1 = '/images/content/blog/word2vec/word2vec1.png';
export const BLOG_WORD2_VEC_EXAMPLE2 = '/images/content/blog/word2vec/word2vec2.png';
export const BLOG_WORD2_VEC_EXAMPLE3 = '/images/content/blog/word2vec/word2vec3.png';
export const BLOG_EVOLUTIONARY_ALGORITHMS = '/images/content/blog/evolutionaryAlgorithms.svg';
export const BLOG_STORYTELLING_WITH_VIDEOS_PWC_NET =
  '/images/content/blog/storyTellingWithVideos/1_UAs1TJp82f7dvBQRvhDICg.png';
export const BLOG_STORYTELLING_WITH_VIDEOS_ATTENTION_ARCHITECTURE =
  '/images/content/blog/storyTellingWithVideos/1_ejqaOGUp-FflTjajPfyNUA.png';
export const BLOG_STORYTELLING_WITH_VIDEOS_LOG_MEL_SPECTOGRAM =
  '/images/content/blog/storyTellingWithVideos/1_0e5wVXqhiexRis-Ud_j3Dw.png';
export const BLOG_STORYTELLING_WITH_VIDEOS_LOG_MEL_SIGMOID_1 =
  '/images/content/blog/storyTellingWithVideos/1_ZRpyUPHTr02JeeW0l4-oFg.png';
export const BLOG_STORYTELLING_WITH_VIDEOS_LOG_MEL_SIGMOID_2 =
  '/images/content/blog/storyTellingWithVideos/1_7vF3wfhYSG0f8dT-9UlCBQ.png';
export const BLOG_STORYTELLING_WITH_VIDEOS_RECURRENT_NETWORKS =
  '/images/content/blog/storyTellingWithVideos/1_9kkCo09whwktqQy4hTd07g.png';
export const BLOG_STORYTELLING_WITH_VIDEOS_GIF =
  '/images/content/blog/storyTellingWithVideos/1_Vv5ZF_lNca4dZhfagpDcuA.gif';
export const BLOG_STORYTELLING_WITH_VIDEOS_TRANSFORMERS_ARCHITECTURE =
  '/images/content/blog/storyTellingWithVideos/1_wPStYVDX1NP15XgLffamSg.png';
export const BLOG_STORYTELLING_WITH_VIDEOS_RESULTS_TABLE =
  '/images/content/blog/storyTellingWithVideos/1_5sIRLK4FZV1QwcGJ74p_eQ.png';
export const BLOG_INSTAFEED_DEMO1 = '/images/content/blog/instafeed/instafeed-demo1.png';
export const BLOG_INSTAFEED_DEMO2 = '/images/content/blog/instafeed/instafeed-demo2.png';
export const BLOG_INSTAFEED_MAIN = '/images/content/blog/instafeed/instafeed.png';
export const BLOG_NOTION_DASHBOARD_MAIN = '/images/content/blog/notionDashboard/tasks.svg';
export const BLOG_NOTION_DASHBOARD_PREVIEW = '/images/content/blog/notionDashboard/tasks.jpg';
export const BLOG_NOTION_DASHBOARD_1 = '/images/content/blog/notionDashboard/dashboard1.png';
export const BLOG_NOTION_DASHBOARD_2 = '/images/content/blog/notionDashboard/dashboard2.png';
export const BLOG_NOTION_DASHBOARD_3 = '/images/content/blog/notionDashboard/dashboard3.png';
export const BLOG_NOTION_DASHBOARD_4 = '/images/content/blog/notionDashboard/dashboard4.png';
export const BLOG_NOTION_DASHBOARD_5 = '/images/content/blog/notionDashboard/dashboard5.png';
export const BLOG_NOTION_DASHBOARD_6 = '/images/content/blog/notionDashboard/dashboard6.png';

export const BLOG_ALGORITHMICS_COVER = '/images/content/blog/algorithmics/cover.svg';

export const BLOG_BACKPROPAGATION_COVER = '/images/content/blog/backpropagation/cover.png';

export const BLOG_CASE_STUDY_OWWN_COVER = '/images/content/blog/deinestudienfinanzierung.png';

export const BLOG_CASE_STUDY_EXOMATTER_COVER = '/images/content/blog/exomatter.png';

export const BLOG_GOOD_TO_GREAT_ML_LLMS = '/images/content/blog/goodToGreatML/llm-intro-cover.png';
export const BLOG_GOOD_TO_GREAT_ML_LLMS_GRAPH =
  '/images/content/blog/goodToGreatML/llm-intro-graph.png';
